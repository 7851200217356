<ng-container appearance="standard" class="p-3 w-70">
    <form class="example-form">
        <div class="shadow-box-card-body ">

            <div class="table-responsive p-3">
                <table class="table">
                    <thead class="head-table">
                        <tr>
                            <th>#</th>
                            <th> {{"languages.garantie.Garantie" | translate}}</th>
                            <th> {{"languages.garantie.groupGarantie" | translate}}</th>
                            <th> {{"languages.garantie.Valeur" | translate}}</th>
                            <th> {{"languages.garantie.valeuraAfficher" | translate}}</th>
                            <th> {{"languages.garantie.nbrSinistre" | translate}}</th>
                            <th> {{"languages.garantie.plafondAns" | translate}}</th>
                            <th> {{"languages.garantie.plafondSinistre" | translate}}</th>

                        </tr>
                    </thead>
                    <tbody class="body-table">
                        <!-- *ngFor="let i of ListG; let index = index" -->
                        <tr *ngFor="let i of ListG; let index = index" >
                            <td>{{index+1}}</td>
                            <td>{{i.code_garantie}}</td>
                            <td>{{i.libelle_groupe}}</td>
                            <td>
                                <mat-form-field appearance="standard" class="md-block">
                                    <input matInput style="text-align: left; padding-right: 15px;" type="text"  required (keyup)="valuechange($event, 'val' ,index )"  [value]="i.valeur ? i.valeur : 0">
                                    <span style="margin-top: 5px; position: absolute; right: 0;">{{i.unite}}</span>
                               </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="standard" class="md-block">
                                    <input matInput style="text-align: left; padding-right: 15px;" type="text"  required (change)="valuechange($event, 'valeur_afficher' ,index )" [value]="i.valeur_afficher ? i.valeur_afficher : ''">
                               </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="standard" class="md-block">
                                    <input matInput style="text-align: left; padding-right: 15px;" type="text"  required  (change)="valuechange($event, 'nbr' ,index )"  [value]="i.Nb_de_sinistres_ans ? i.Nb_de_sinistres_ans : 0">
                               </mat-form-field>


                            </td>
                            <td>
                                <mat-form-field appearance="standard" class="md-block">
                                    <input matInput style="text-align: left; padding-right: 15px;" type="text"  required (change)="valuechange($event, 'P_ans', index)"   [value]="i.Plafond_ans ? i.Plafond_ans : 0">
                                    <span style="margin-top: 5px; position: absolute; right: 0;">{{currency}}</span>
                               </mat-form-field>


                            </td>
                            <td>
                                <mat-form-field appearance="standard" class="md-block">
                                    <input matInput style="text-align: left; padding-right: 15px;" type="text" required (change)="valuechange($event , 'P_sinistre' ,index)"  [value]="i.Plafond_sinistre ? i.Plafond_sinistre : 0">
                                    <span style="margin-top: 5px; position: absolute; right: 0;">{{currency}}</span>
                               </mat-form-field>
                            </td>

                        </tr>

                    </tbody>
                </table>
              <p class="text-center" *ngIf="ListG?.length<1 ">
                     {{"languages.garantie.noGaranties" | translate}}
                </p>
            </div>
        </div>
    </form>
</ng-container>
