<div class="card-head" *ngIf="!ModeAjout">
    <div class="row">
        <div class="col-9 mt-2">
            <p>
                {{"languages.settings.cycleVieNum" | translate }} :
                <label class="label-card-info">
            {{cycleBpm?.real_id}}
          </label>
            </p>
            <p>
                {{"languages.settings.nomCycleVie" | translate }} :
                <label class="label-card-info">
          {{cycleBpm.libele}}
        </label>
            </p>
        </div>
        <div *ngIf="this.affecterCycleFormControl.value.id" class="col-3 text-left">
            <p> {{"languages.listing.Statut" | translate }} :
                <label class="label-card-info">
          {{this.affecterCycleFormControl.value.etat}}
        </label>
            </p>
        </div>
    </div>
</div>

<div class="card-head text-center">
    <div class="row details-header">
        <button type="button" (click)="retourToList()" class="btn-dt-retourr mt-1" >
            <div class="icon-position">
                <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>
            </div>
        </button>
        <div class="row">
            <div class="row details-spinner">
                <div class="col">
                    <ng-container *ngIf="show_loading_add">
                        <div class="mt-2">
                            <mat-spinner [diameter]="30"></mat-spinner>
                        </div>
                    </ng-container>
                </div>
                <div class="col mr-3">
                    <button type="button" class="btn-load" id="clear" (click)="clearForm()">
          <i class="fa-solid fa-rotate-right"></i>
    </button>
                </div>
            </div>
            <button type="button" class="btn-dt-save" (click)="Enregistrer()" [disabled]="show_loading_add">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
    {{"languages.buttons.enregistrer" | translate }}
  </button>
        </div>
    </div>
</div>

<div class="card p-3 border-0 m-3">
    <div class="row mt-3">
        <div class="col-6">
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label>{{"languages.alerts.Libelle" | translate }} <span style="color: red;"> *</span></mat-label>
                    <input name="nom_organisme" [(ngModel)]="cycleBpm.libele"   [formControl]="libelleFormControl" matInput  placeholder="Libelle">
                    <mat-error *ngIf=" (libelleFormControl.dirty || libelleFormControl.touched) ">
                        <div *ngIf="libelleFormControl.hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                             {{"languages.campagne.obligLibelle" | translate }}
                        </div>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-6" *ngIf="!ModeAjout">
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label> {{"languages.listing.Statut" | translate }}</mat-label>
                    <input type="text"  matInput (change)="onStatusChange($event.target.value)" [formControl]="affecterCycleFormControl" name="workflow" [matAutocomplete]="autoWorkflowComm">
                    <mat-autocomplete autoActiveFirstOption [displayWith]="displayCycleVieFn" #autoWorkflowComm="matAutocomplete">
                        <mat-option *ngFor="let option of listCycleVieFilter | async" [value]="option">
                            {{option.etat}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="affecterCycleFormControl.hasError('invalidAutocompleteObject') &&  !affecterCycleFormControl.hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>   {{"languages.settings.verifyStatut" | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        </div>
        <div class="row">
        <div class="col-6">
            <div class="div-list-no-existt principal-background mx-1 p-3  w-70">

                <div class="row w-100 justify-content-center text-center ">
                    <div class="col-12 justify-content-center text-center">
                        <label class="mb-2 ml-2 justify-content-center text-center">{{"languages.settings.addService" | translate }}</label>

                    </div>
                    <div class="col-12 justify-content-center text-center ">
                        <label class="mb-2 ml-2 title-style-normal principal-color  ">{{"languages.settings.Services" | translate }}</label>
                    </div>
                </div>


                <div class="row scroll">
                    <span class="example-list-section">

                          <div  *ngFor="let service of listServices">
                            <mat-checkbox   [(ngModel)]="service.selected"
                            (ngModelChange)="updateSelectedServices(service)">
                              {{service.libele}}
                            </mat-checkbox>
                          </div>

                      </span>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="div-list-no-existt secondry-background mx-1 p-3  w-70">
                <div class="row justify-content-center background sticky-2">
                    <div class="col-12 text-center">
                        <label class="mb-2 ml-2 text-center ">{{"languages.settings.GlisserService" | translate }}
                        </label>
                    </div>
                    <div class="col-12 text-center">
                        <label class="mb-2 ml-2 title-style-normal secondry-color ">{{"languages.settings.ServicesChoisis" | translate }} </label>

                    </div>
                </div>


                <div  cdkDropList (cdkDropListDropped)="onDrop($event)" class="  justify-content-center scroll">

                        <div class="  mx-auto list-item justify-content-center " *ngFor="let selectedService of selectedServices"
                        cdkDrag>
                        <div class="col-2 cursor  mt-3">
                            <img src="assets\imageSvg\dragDropIcon.svg" cdkDropList [cdkDropListOrientation]="'vertical'"
                            (cdkDropListDropped)="onDrop($event)">


                        </div>
                        <div class="col w-70 mt-3 ml-2">
                            {{ selectedService.libele }}
                        </div>
                        <div class="col-2 w-100 mt-3 cursor">
                            <img src="assets\imageSvg\deleteIcon.svg"  (click)="deleteService(selectedService)">

                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>



</div>

