<div id="tickets_model" [ngClass]="{'cover': !allowed_to_update_affaire}">
    <div class="card bg-white border-0 p-3">
        <div class="card-doc-title ml-3 row">
<i class="fa-solid fa-ticket-simple mr-2 fa-lg mt-2"></i>
            {{'languages.navbar.OppDetailsTickets' | translate}}
        </div>
        <button type="button" class="btn-document-add btn-position-add" [attr.data-title]="'languages.ticket.addTicket' | translate" data-toggle="modal" data-target="#AjoutTicketModal">
            <i class="fa-solid fa-plus fa-lg"></i>
        </button>
        <ng-container *ngIf="!show_loading_add">

            <div class="row mb-3 mt-3">
                <div class="text-center m-auto ">
                    <mat-spinner [diameter]="30"></mat-spinner>

                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="show_loading_add">
            <div class="my-3  scrollbar" [ngClass]="{'table-scroll': ticket_details.length > 10 }">
                <table class="table align-items-center" id="dataTable1">
                    <thead class="head-table">
                        <tr>
                            <th> {{'languages.opportunite.label_creationdate' | translate}}</th>
                            <th> {{'languages.communication.creator' | translate}}</th>

                            <th>{{'languages.navbar.Détails' | translate}}</th>
                            <th>{{'languages.ticket.ticketParent' | translate}}</th>
                            <th>{{'languages.ticket.Category' | translate}}</th>
                            <th>{{'languages.listing.Statut' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody class="body-table">
                        <tr *ngFor="let ticket of ticket_details">
                            <td> {{ticket.date_creation}}</td>
                            <td>{{ticket.createur_name}}</td>

                            <td>
                                <button *ngIf="ticket.id" type="button" class="btn-dt-link" (click)="NaviagateToTicketDetails(ticket.id)">
<i class="fa-solid fa-link fa-icon-style"></i>
                                {{'languages.ticket.lienTicket' | translate}} 
                              </button>
                              <span *ngIf="!ticket.id">-</span>
                            </td>
                              <td>
                              <button *ngIf="ticket.id_ticket_parent" type="button" class="btn-dt-link" (click)="NaviagateToTicketDetails(ticket.id_ticket_parent)">
<i class="fa-solid fa-link fa-icon-style"></i>
                                {{'languages.ticket.lienTicket' | translate}} 
                              </button>
                              <span *ngIf="!ticket.id_ticket_parent">-</span>
                            </td>
                            <td>{{ticket.categorie}}</td>
                            <td>
                                <div class="label-status " [ngStyle]="{'background':ticket ?.statut_background}">
                                    <span class="pt-2" [ngStyle]="{'color':ticket?.statut_color}">
                                        <i class=" {{ticket?.icon}} mr-1" ></i>
                                {{ticket.statut}}
                              </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="ticket_details.length < 1" class="text-center font-weight-bold pt-1">
                    <div class="col-12  d-flex justify-content-center">
                        <img src="assets/icons/nodata.svg">

                      </div>
                      <div class="col-12  d-flex justify-content-center">
                        <p class="noData">  {{'languages.affaire.NoticketsAffaire' | translate}}</p>
                      </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="modal fade" id="AjoutTicketModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content" style="width: 60rem; ">
                <div class="modal-body p-0">
                    <app-add-tickets-affaire [prospect_id]="prospect_id"></app-add-tickets-affaire>
                </div>
            </div>
        </div>
    </div>
</div>
