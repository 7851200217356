import { FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  AfterViewChecked,
} from '@angular/core';
 
import * as $ from 'jquery';
import { DocumentProduit } from 'src/app/entity/Bpm/LigneProduits';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'shared-list-documents',
  templateUrl: './shared-list-documents.component.html',
  styleUrls: ['./shared-list-documents.component.css'],
})
export class SharedListDocumentsComponent implements OnInit, OnChanges {
  @Output() onShareListDocument: EventEmitter<DocumentProduit[]> = new EventEmitter();
  @Input() documentList: DocumentProduit[];
  @Input() defaultDocumentList: DocumentProduit[];
   
  formDocument: FormGroup;
  typeDocuments: DocumentProduit[] = [];
  id: string;
  edit: boolean = false;
  loaderListDocument: boolean = false;
  public docAutoComplete$: Observable<DocumentProduit[]> = null;
  dataTable: any;
  accee: boolean;
  miniListing: any;
  alerts: any;

  constructor(
    private apiAdminBpmServices: ApiAdminBpmService,
    private chRef: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.typeDocuments = this.documentList;
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
      });
    });

    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
    });
    this.formDocument = new FormGroup({
      documentText: new FormControl(),
      documentTextId: new FormControl(),
    });

    this.docAutoComplete$ = this.formDocument.controls.documentText.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((value) => {
        if (value !== '') {
          return this.getListTypeDocument();
        } else {
          // if no value is present, return null
          return of(null);
        }
      })
    );
  }

  alertDelete(idDoc, index) {
    swal
      .fire({
        title: this.alerts.confirmDelete,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.alerts.Oui,
        cancelButtonText: this.alerts.Non,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.typeDocuments.splice(index, 1);
        }
      });
  }

  getListTypeDocument(): Observable<DocumentProduit[]> {
    this.loaderListDocument = true;
    return this.apiAdminBpmServices.rechercheTypeDocument(this.formDocument.value.documentText.toLowerCase()).pipe(
      map((res) => {
        this.typeDocuments.forEach(function (item) {
          const index: number = res.type_doc_list.findIndex((i) => i.id === item.id);
          if (index !== -1) {
            res.type_doc_list.splice(index, 1);
          }
        });
        this.loaderListDocument = false;
        return res.type_doc_list;
      }),
      catchError((_) => {
        this.loaderListDocument = false;
        return of(null);
      })
    );
  }

  getDocumentInfo(id, index) {
    this.alertDelete(id, index);
  }

  onSelectDoc(document: DocumentProduit) {
    this.formDocument.controls.documentTextId.setValue(document.id);
    const documentproduit = new DocumentProduit();
    documentproduit.libelle = document.libelle;
    documentproduit.id = document.id;
    documentproduit.real_id = document.real_id;
    documentproduit.oblig = document.oblig !== undefined ? document.oblig : 0;
    this.pushInfo(documentproduit);
    this.formDocument.controls.documentText.reset();
  }

  pushInfo(document: DocumentProduit) {
    const table: any = $('#TableSharedDocument');

    table.dataTable().fnDestroy();
    this.typeDocuments.unshift(document);
    this.onShareListDocument.emit(this.typeDocuments);

    this.translate.get('languages').subscribe((object: any) => {
      this.miniListing = object.miniListing;
      this.loadtable();
    });
    this.edit = true;
    table.dataTable().fnDestroy();
  }

  isObligatoire(event, item) {
    if (event.target.checked) {
      item.oblig = '1';
    } else {
      item.oblig = '0';
    }
  }

  loadtable() {
    this.chRef.detectChanges();

    const table: any = $('#TableSharedDocument');
    this.dataTable = table.DataTable({
      destroy: true,
      stateSave: true,
      pagingType: 'full_numbers',
      language: {
        sProcessing: this.miniListing.traitement,
        searchPlaceholder: this.miniListing.search,
        sSearch: '<p aria-hidden="true"></p>',
        sLengthMenu: this.miniListing.Afficher + '_MENU_' + this.miniListing.element,
        sInfo:
          this.miniListing.displayingElement +
          '_START_' +
          this.miniListing.label_a +
          '_END_' +
          this.miniListing.label_sur +
          '_TOTAL_' +
          this.miniListing.element,
        sInfoEmpty: this.miniListing.sInfoEmpty,
        sInfoFiltered: '(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)',
        sInfoPostFix: '',
        sLoadingRecords: this.miniListing.chargement,
        sZeroRecords: this.miniListing.noRecords,
        sEmptyTable: this.miniListing.emptyTable,
        oPaginate: {
          sFirst: '<i class="fa fa-angle-double-left" style="color: #007bff"></i>',
          sPrevious: '<i class="fa fa-angle-left" style="color: #007bff"></i>',
          sNext: '<i class="fa fa-angle-right" style="color: #007bff"></i>',
          sLast: '<i class="fa fa-angle-double-right" style="color: #007bff"></i>',
        },
        oAria: {
          sSortAscending: this.miniListing.sSortAscending,
          sSortDescending: this.miniListing.sSortDescending,
        },
        select: {
          rows: {
            _: '%d' + this.miniListing.selectedLines,
            0: this.miniListing.zeroselected,
            1: this.miniListing.oneselected,
          },
        },
      },
    });
  }
}
