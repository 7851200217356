<div style="margin: auto;">
  <div class="row details-nav">
    <div class="col-4 p-2">
      <button (click)="EmitFilterAncienContart()" (click)="typeContrat('0')" class="button-style left-btn">
        {{'languages.opportunite.label_nr' | translate}}
      </button>
    </div>
    <div class="col-4 p-2">
      <button (click)="EmitFilterAncienContart()" (click)="typeContrat('0')" class="button-style middle-btn">
        {{'languages.opportunite.label_notinsured' | translate}}
      </button>
    </div>
    <div class="col-4 p-2">
      <button class="button-style right-btn" (click)="EmitFilterAncienContart()" (click)="typeContrat('1')">
        {{'languages.opportunite.label_insured' | translate}}
      </button>
    </div>
  </div>
  <div *ngIf="assure" class="row details-body">
    <div class="col-6">
      <div class="form-group">
        <mat-form-field appearance="fill" style="width: 100%;margin-top: -14px;">
          <input matInput [matDatepicker]="echeance" [(ngModel)]="date_ech" placeholder="{{'languages.opportunite.label_mainduedate' | translate}}" >
          <mat-datepicker-toggle matSuffix [for]="echeance"></mat-datepicker-toggle>
          <mat-datepicker (closed)="EmitFilterAncienContart()" #echeance></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="col-6">
      <ng-container *ngIf="allCompagnie.length > 0">
        <mat-form-field style="width: 100%;margin-top: -4px;">
          <mat-label>{{'languages.listing.Compagnie' | translate}}</mat-label>
          <mat-chip-list #Compagnie>
            <mat-chip *ngFor="let compagnie of ListCompagnies" [selectable]="selectable" [removable]="removable"
              (removed)="removeCompagnie(compagnie)" (removed)="EmitFilterAncienContart()">
              {{compagnie}}
              <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
            </mat-chip>
            <input (click)="EmitFilterAncienContart()" placeholder="{{'languages.opportunite.label_company_select' | translate}}"  #compagnieInput
              [formControl]="compagnieFromCtrl" [matAutocomplete]="autoCompagnies" [matChipInputFor]="Compagnie"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoCompagnies="matAutocomplete" (optionSelected)="selectedCompagnie($event)">
            <mat-option *ngFor="let compagnie of filteredCompagnie | async" [value]="compagnie.siren">
              {{compagnie.denomination}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-6" style="padding-left: 0px;">
          <div class="form-group">
            <label></label>
            <mat-form-field appearance="fill" style="width: 100%;margin-top: -13px;">
              <mat-select (selectionChange)="EmitFilterAncienContart();getNombre($event)"
                [(ngModel)]="ancienContrat.operateur" placeholder="{{'languages.affaire.PAN_TTC' | translate}}">
                <mat-option value="=">
                   {{'languages.opportunite.label_equals' | translate}}
                </mat-option>
                <mat-option value="<=">
                  {{'languages.opportunite.label_lessorequal' | translate}}
                </mat-option>
                <mat-option value=">">
                  {{'languages.opportunite.label_greaterthan' | translate}}
                </mat-option>
                <mat-option value=">=">
                  {{'languages.opportunite.label_greaterorequal' | translate}}
                </mat-option>
                <mat-option>
                  {{'languages.opportunite.label_notassigned' | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-6" style="padding-right: 0px;margin-top: -5px;">
          <mat-form-field style="width: 100%;">
            <input [disabled]="!noValue" (click)="EmitFilterAncienContart()" (keyup.enter)="EmitFilterAncienContart()"
              [(ngModel)]="ancienContrat.pan_ttc" matInput placeholder="{{'languages.opportunite.label_write.number' | translate}}" type="number"
              pattern="[-+]?[0-9]*[.,]?[0-9]+" name="nombre" #nombre="ngModel">
          </mat-form-field>
          <div *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)" class="message-erreur">
            <div *ngIf="nombre.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>&nbsp;{{'languages.opportunite.check_onlyNumber' | translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
