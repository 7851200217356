import { Motif } from 'src/app/pages/parametres/gestion-status-affaire/fiche-status-affaire/fiche-status-affaire.component';
import { BankInformation } from './../BankInformation';
export class Statut {
  libelle: string = '';
  type: string = '';
  validation: string = '';
}
export class Frais {
  montant: any = '';
  note: string = '';
  id_mode_paiement: string = '';
  mode_paiement: string = '';
  id_affaire: string = '';
  id_frais: string = '';
  date_creation: string = '';
  date_due: string = '';
  statut: Statut[] = [];
  type: string = '';
  tva: any = '';
  timbre: any = '';
}

export class Gamme {
  active: any;
  avantages_tiers_payant: any;
  cie: any;
  conditions_souscription: any;
  denomination: any;
  description: any;
  formulaire_adhesion: any;
  garanties: any;
  garanties_herite: any;
  id: any;
  id_contrat: any;
  id_externe: any;
  ipid: any;
  logo: any;
  nom: any;
  notice_information: any;
  pro: any;
  support_commercial: any;
  tableaux_de_garanties: any;
  type_contrat: any;
  url_tarification: string;
}

export class Contrat {
  Fractionnement_prime: number;
  affaire_fils: any[] = [];
  affaire_parent: string = '';
  autre_affaires: any[] = [];
  cmp: string = '';
  bareme_code: string = '';
  code_bareme: string = '';
  date_deff: string = '';
  date_envoi_res: string = '';
  date_rapelle: string = '';
  denomination: string = '';
  id: string = '';
  description: string = '';
  id_contrat: string = '';
  id_opp: number;
  id_prospect: string = '';
  laison_contrat: any[] = [];
  list_tags: any[] = [];
  list_tags_libelle: any[] = [];
  mca: string = '';
  mcm: string = '';
  mode_paiement: string = '';
  mode_paiement_libelle: string = '';
  montant_ht: string = '';
  nb_adh: string = '';
  nom_p: string = '';
  nom_produit: string = '';
  num_contrat: string = '';
  pm_base: string = '';
  souscription: string = '';
  type_contrat: string = '';
  nom_commerciale: string = '';
  note: string = '';
  num_affaire: string = '';
  id_opp_md5: string = '';
  status: string = '';
  etat_dossier_id: string = '';
  allowed_to_update: any;
  bien: number;
  person: number;
  gamme: Gamme = new Gamme();
  nom_pros: string = '';
  motif: Motif = new Motif();
  option: string;
  renfort: string;
  nom_game: string;
}

export class MainProspect {
  CP: string = '';
  DN: string = '';
  adresse_mail: string = '';
  adresses_adresse: string = '';
  adresses_ville: string = '';
  campagne_id: string = '';
  civilite: string = '';
  complement_adr: string = '';
  description: string = '';
  first_campagne: string = '';
  id: string = '';
  interlocuteur: string = '';
  mobile: string = '';
  name: string = '';
  nb_enfants: string = '';
  post: string = '';
  prospects_civ: string = '';
  prospects_id: string = '';
  prospects_situation: string = '';
  relation: string = '';
  situation: string = '';
  streetName: string = '';
  streetNumber: string = '';
  surname: string = '';
  tags: any[] = [];
  tel: string = '';
  tel2: string = '';
  users_nom: string = '';
  users_prenom: string = '';
  ville: string = '';
}
export class AffaireProspect {
  main_prospect: MainProspect = new MainProspect();
}

export class Solde {
  date: string = '';
  montant: any;
}
export class AffaireDetails {
  contrat: Contrat = new Contrat();
  prospect: AffaireProspect = new AffaireProspect();
  frais: Frais = new Frais();
  info_bancaire: BankInformation[] = [];
  avenant: any;
  solde: Solde;
}
