
<div class=" row" style="margin-left: 30px;">
    <h2 class=line>
      Outils
    </h2>
   </div>
   <div class="row" style="padding-right: 30px;">
     <div class="col-3">
       <button class="style-btn-bubble" data-toggle="modal" data-target="#ModelEmail" (click)="getListModelCommunication('M')">
         <div class="communication-bubble">
           <div class="row">
             <div class="col logo-bubble">
               <img class="background-bubble" src="/assets/img/communication_icons/email-icon-communication.svg" alt="">
             </div>
           </div>
           <div class="row">
             <div class="col text-center text-bubble">
               <p>Email</p>
             </div>
           </div>
         </div>
       </button>
     </div>
     <div class="col-3">
       <button class="style-btn-bubble" data-toggle="modal" data-target="#ModelVisio">
         <div class="communication-bubble">
           <div class="row">
             <div class="col logo-bubble">
               <div class="background-bubble">
                 <img class="background-bubble" src="/assets/img/communication_icons/visio-icon-communication.svg" alt="">
               </div>
             </div>
           </div>
           <div class="row">
             <div class="col text-center text-bubble">
               <p>Visio</p>
             </div>
           </div>
         </div>
       </button>
     </div>
     <div class="col-3">
       <button class="style-btn-bubble" data-toggle="modal" data-target="#ModelCourrier" (click)="getListModelCommunication('C')">
         <div class="communication-bubble">
           <div class="row">
             <div class="col logo-bubble">
               <div class="background-bubble">
                 <img class="background-bubble" src="/assets/img/communication_icons/courrier-icon-communication.svg" alt="">
               </div>
             </div>
           </div>
           <div class="row">
             <div class="col text-center text-bubble">
               <p>Courrier</p>
             </div>
           </div>
         </div>
       </button>
     </div>
     <div class="col-3">
       <button class="style-btn-bubble" data-toggle="modal" data-target="#ModelSMS" (click)="getListModelCommunication('S')">
         <div class="communication-bubble">
           <div class="row">
             <div class="col logo-bubble">
               <div class="background-bubble">
                 <img class="background-bubble" src="/assets/img/communication_icons/sms-icon-commnication.svg" alt="">
               </div>
             </div>
           </div>
           <div class="row">
             <div class="col text-center text-bubble">
               <p>SMS</p>
             </div>
           </div>
         </div>
       </button>
     </div>
   </div>
   <!-----------------------------Email Model------------------------------------>
  <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" id="ModelEmail"
        aria-labelledby="myLargeModalLabel" aria-hidden="true">
     <div class="modal-dialog modal-lg">
       <div class="modal-content">
         <div class="modal-header">
           <h3 class="modal-title">Nouveau Email</h3>
           <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetForm()">
             <span aria-hidden="true">&times;</span>
           </button>
         </div>
   
         <ng-container>
           <div class="modal-body">
             <h3 >Selectionnez Modal:</h3>
             <ng-container *ngIf="show_loading">
               <div class="row">
                 <div class="col-12 text-center">
                   <div style="width: min-content; margin: auto; margin-bottom: 50px; margin-top: 50px">
                     <mat-spinner [diameter]="50"></mat-spinner>
                   </div>
                 </div>
               </div>
             </ng-container>
             <div class="row" *ngIf="!show_loading">
               <div class="col">
                 <ul>
                   <li class="nav-item dropdown" *ngFor="let model of communicationModel; let i=index">
                     <ng-container *ngIf="model.childs.length>0">
                       <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                         class="nav-link dropdown-toggle btn-style dropbtn">
                         {{expandFileName(model.nom)}}
                       </a>
                       <ng-container *ngTemplateOutlet="dropdownList;context:{'menu':model}"></ng-container>
                     </ng-container>
                     <ng-container *ngIf="model.childs.length<1">
                       <ng-container *ngTemplateOutlet="defaultBtnModel;context:{'menu':model}"></ng-container>
                     </ng-container>
                   </li>
                 </ul>
               </div>
             </div>
             <form [formGroup]="formModel">
               <div class="form-group">
                 <mat-form-field class="full-width" appearance="standard" style="width: 100%;">
                   <mat-label>Vers</mat-label>
                   <input type="text" matInput formControlName="mail_destinataire" name="mail_destinataire">
                 </mat-form-field>
               </div>
               <div class="form-group">
                 <mat-form-field class="full-width" appearance="standard" style="width: 100%;">
                   <mat-label>Sujet</mat-label>
                   <input type="text" matInput formControlName="sujet" name="sujet">
                 </mat-form-field>
               </div>
               <div class="form-group">
                 <label class="col-form-label">Paramètres Email:</label>
                 <ckeditor   [config]="config" style="width: 100%;margin-top: 50px" name="body_content" formControlName="body_content"
                           [editor]="Editor" data="{{content}}"></ckeditor>
               </div>
               <div class="form-group text-right">
                 <ng-container *ngIf="ListFiles.length>0">
                   <div class="row">
                     <div class="col-2 file-selected-style"  *ngFor="let file of ListFiles; let index = index">
                       <div class="row">
                         <div class="col-2 delete-btn" (click)="dropFile(index)">
                             <i class="fa-solid fa-trash"></i>
                         </div>
                         <div class="col-10" style="padding-right: 0px;text-align: end;">
                           {{expandFileName(file.name)}}
                         </div>
                       </div>
                     </div>
                   </div>
                 </ng-container>
               </div>
             </form>
           </div>
           <div class="modal-footer">
             <button type="button" class="btn-cancel"  data-dismiss="modal" (click)="resetForm()">Fermer</button>
             <button type="submit" class="btn btn-send" data-dismiss="modal" (click)="SendEmail()">
              <i class="fa-solid fa-circle-check fa-lg mr-1"></i>
               Enregistrer
             </button>
           </div>
         </ng-container>
       </div>
     </div>
   </div>
   <!-----------------------------Visio Model------------------------------------>
   <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" id="ModelVisio"
        aria-labelledby="myLargeModalLabel" aria-hidden="true">
     <div class="modal-dialog modal-lg">
       <div class="modal-content">
         <div class="modal-header">
           <h3 class="modal-title">Visio</h3>
           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
             <span aria-hidden="true">&times;</span>
           </button>
         </div>
         <form [formGroup]="formModel">
           <div class="modal-body">
           
             <h3 class="col-form-label"> Visio</h3>
           </div>
         </form>
       </div>
     </div>
   </div>
   <!-----------------------------Courrier Model------------------------------------>
 <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" id="ModelCourrier"
        aria-labelledby="myLargeModalLabel" aria-hidden="true">
     <div class="modal-dialog modal-xl" style="left: 75px;">
       <div class="modal-content">
         <div class="modal-header">
           <h3 class="modal-title">Nouveau Courrier</h3>
           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
             <span aria-hidden="true">&times;</span>
           </button>
         </div>
         <div class="modal-body"  style="padding: 0px;">
           <app-add-maileva [id_opportunite]="opportunity_id"></app-add-maileva>
         </div>
       </div>
     </div>
   </div> 
   <!-----------------------------SMS Model------------------------------------>
 <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" id="ModelSMS"
        aria-labelledby="myLargeModalLabel" aria-hidden="true">
     <div class="modal-dialog modal-lg">
       <div class="modal-content">
         <div class="modal-header">
           <h3 class="modal-title">Nouveau SMS</h3>
           <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetForm()">
             <span aria-hidden="true">&times;</span>
           </button>
         </div>
         <div class="modal-body">
           <h3>Selectionnez un Modèle:</h3>
           <ng-container *ngIf="show_loading">
             <div class="row">
               <div class="col-12 text-center">
                 <div style="width: min-content; margin: auto; margin-bottom: 50px; margin-top: 50px">
                   <mat-spinner [diameter]="50"></mat-spinner>
                 </div>
               </div>
             </div>
           </ng-container>
             <div class="row" *ngIf="!show_loading">
               <div class="col">
                 <ul>
                   <li class="nav-item dropdown" *ngFor="let model of communicationModel; let i=index">
                     <ng-container *ngIf="model.childs.length>0">
                       <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                         class="nav-link dropdown-toggle btn-style dropbtn">
                         {{expandFileName(model.nom)}}
                       </a>
                       <ng-container *ngTemplateOutlet="dropdownList;context:{'menu':model}"></ng-container>
                     </ng-container>
                     <ng-container *ngIf="model.childs.length<1">
                       <ng-container *ngTemplateOutlet="defaultBtnModel;context:{'menu':model}"></ng-container>
                     </ng-container>
                   </li>
                 </ul>
               </div>
             </div>
             <h4 class="col-form-label text-center subTitle" >Paramètres SMS:</h4>
             <form [formGroup]="formModel">
                 <div class="form-group">
                   <mat-form-field class="full-width" appearance="standard" style="width: 100%;">
                     <mat-label>Vers</mat-label>
                     <input type="text" matInput formControlName="mail_destinataire" name="mail_destinataire">
                   </mat-form-field>
                 </div>
                 <div class="form-group">
                   <mat-form-field class="full-width" appearance="standard" style="width: 100%;">
                     <mat-label>Sujet</mat-label>
                     <input type="text" matInput formControlName="sujet" name="sujet">
                   </mat-form-field>
                 </div>
                 <div class="form-group">
                   <label class="col-form-label">Modèle SMS:</label>
                   <ckeditor  [config]="config"  style="width: 100%;margin-top: 50px" name="body_content" formControlName="body_content"
                             [editor]="Editor" data="{{content}}"></ckeditor>
                 </div>
             </form>
         </div>
         <div class="modal-footer">
           <button type="button" class="btn-cancel" data-dismiss="modal" (click)="resetForm()">Fermer</button>
           <button type="submit" class="btn btn-send" (click)="sendSMSMessage()" data-dismiss="modal">
            <i class="fa-solid fa-circle-check fa-lg mr-1"></i>
             Enregistrer
           </button>
         </div>
       </div>
     </div>
   </div>
 <ng-template #loading>
     <div class="col-12 text-center">
       <div style="width: min-content; margin: auto; margin-bottom: 50px; margin-top: 50px">
         <mat-spinner [diameter]="50"></mat-spinner>
       </div>
     </div>
   </ng-template>
   <ng-template #dropdownList let-menuList="menu">
     <ul class="dropdown-menu border-0 shadow">
       <ng-container *ngFor="let menu of menuList.childs">
         <ng-container *ngIf="menu.childs.length>0">
           <ng-container *ngTemplateOutlet="subMenu;context:{'menu':menu}"></ng-container>
         </ng-container>
         <ng-container *ngIf="menu.childs.length<1">
           <ng-container *ngTemplateOutlet="subElement;context:{'menu':menu}"></ng-container>
         </ng-container>
       </ng-container>
     </ul>
   </ng-template>
   
   <ng-template #subElement let-element="menu">
     <li><a (click)="closeAllDropDownElements($event); setComValue(element.id)" class="dropdown-item">{{element.nom}} </a></li>
   </ng-template>
   
   <ng-template #subMenu let-element="menu">
     <li class="dropdown-submenu">
       <a (click)="showSubMenu($event)" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          class="dropdown-item dropdown-toggle">{{element.nom}}</a>
       <ng-container *ngTemplateOutlet="dropdownList;context:{'menu':element}"></ng-container>
     </li>
   </ng-template>
   <ng-template #defaultBtnModel let-model="menu">
     <a (click)="getInfoModel(model.mail,model.nom,model.simple_content,model.id)" aria-haspopup="true" aria-expanded="false"
         class="nav-link  btn-style dropbtn">
         {{expandFileName(model.nom)}}
     </a>
   </ng-template>