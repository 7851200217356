import { Validators , FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { startWith, map } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { Compagnie } from 'src/app/entity/Compagnie';
import { InformationsAncienContrat } from '../../../../../entity/Opportunity/FilterOpportunite';
import { Observable, Subscription } from 'rxjs';
 
import { DatePipe } from '@angular/common';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
@Component({
  selector: 'app-filtres-info-ancien-contrat',
  templateUrl: './filtres-info-ancien-contrat.component.html',
  styleUrls: ['./filtres-info-ancien-contrat.component.css'],
})
export class FiltresInfoAncienContratComponent implements OnInit, OnChanges, OnDestroy {
  assure = false;
  ancienContrat: InformationsAncienContrat = new InformationsAncienContrat();
  @Output() onPushFilterAncienContart: EventEmitter<InformationsAncienContrat> = new EventEmitter();

  compagnieSelected: any[] = [];
   
  @Output() returnToparent = new EventEmitter<boolean>();
  @Input() Reset: any;
filtre : any
  // Mat Chips Compagnie
  visible = false;
  selectable = true;
  removable = true;
  date_ech: string = '';
  separatorKeysCodes: number[] = [ENTER, COMMA];
  compagnieFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredCompagnie: Observable<Compagnie[]>;
  ListCompagnies: string[] = [];
  allCompagnie: Compagnie[] = [];

  _unsubscribe: Subscription;

  @ViewChild('compagnieInput') compagnieInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoCompagnies') matAutocompleteCompagniess: MatAutocomplete;
  noValue: boolean;

  constructor(private apiCampagniesService: ApiCampagniesService, private datePipe: DatePipe) {}

  ngOnDestroy(): void {
    this._unsubscribe.unsubscribe()
  }

  // Compagnie -----------------------------------------------------------------------------------------------
  selectedCompagnie(event: MatAutocompleteSelectedEvent): void {
    if (!this.compagnieSelected.includes(event.option.value)) {
    this.ListCompagnies.push(event.option.viewValue);
    this.compagnieSelected.push(event.option.value);}
    this.compagnieInput.nativeElement.value = '';
    this.compagnieFromCtrl.setValue(null);
    this.EmitFilterAncienContart()
  }

  removeCompagnie(compagnie: string): void {
    const index = this.ListCompagnies.indexOf(compagnie);
    if (index >= 0) {
      this.ListCompagnies.splice(index, 1);
      this.compagnieSelected.splice(index, 1);
    }
  }

  private _filterCompagnie(): Compagnie[] {
    let filterValue = this.compagnieFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allCompagnie.filter((compagnie) =>
      compagnie.denomination != null
        ? compagnie.denomination.toLowerCase().indexOf(filterValue.toLowerCase()) === 0
        : null
    );
  }

  ngOnInit(): void {
    this.filtre = localStorage.getItem('filtreOpportunite');
    this.filtre =JSON.parse(this.filtre)
    if (this.filtre) {
      this.ancienContrat=this.filtre.info_contrat
      this.date_ech = this.ancienContrat.date_ech
    }
    this.getCompagnie();
    this.filteredCompagnie = this.compagnieFromCtrl.valueChanges.pipe(
      startWith(null),
      map((compagnie: string | null) => (compagnie ? this._filterCompagnie() : this.allCompagnie.slice()))
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetProsForm(changes.Reset.currentValue);
  }

  resetProsForm(Reset) {
    if (Reset === true) {
      this.ancienContrat.date_ech = '';
      this.date_ech = '';
      this.ListCompagnies = [];
      this.ancienContrat.list_cie = this.compagnieSelected = [];
      this.ancienContrat.operateur = '';
      this.ancienContrat.pan_ttc = '';
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterAncienContart.emit(this.ancienContrat);
    }
  }

  typeContrat(type) {
    if (this.assure === false) {
      this.assure = true;
      this.ancienContrat.dossier_assure = type;
    } else this.assure = false;
    this.ancienContrat.dossier_assure = type;
  }

  getCompagnie() {
    this._unsubscribe = this.apiCampagniesService.getListCompagnies().subscribe((data: Compagnie[]) => {
      this.allCompagnie = data;
      if (this.filtre) {
        if (this.ancienContrat?.list_cie.length > 0) {
          this.ListCompagnies = this.allCompagnie
            .filter(cie => this.ancienContrat?.list_cie.includes(cie.siren))
            .map(cieAff => {
              this.compagnieSelected.push(cieAff.siren);
              return cieAff.denomination
            });
        }}
    });

  }

  EmitFilterAncienContart() {
    this.ancienContrat.list_cie = this.compagnieSelected;

    this.ancienContrat.date_ech = this.datePipe.transform(this.date_ech, 'yyyy-MM-dd');
    this.onPushFilterAncienContart.emit(this.ancienContrat);
  }

  getNombre(event: any) {
    if (event) {
      this.noValue = true;
    }
  }
}
