<div class="col card" filesDnd (fileDropped)="onFileDropped($event,fileType)">
    <div class="row">
      <div class="col">
        <div class="text-right">
            <label style="color: #2A96FF; font-weight: 500;margin-right: 10px;">Upload fichiers</label>
            <button class="btn-style" (click)="onSelectFiles(files)">
              <label>
                <i class="fa-solid fa-paper-plane fa fa-lg add-document"></i>

              </label>
          </button>
        </div>
        <div *ngIf="files?.length<1">
          <i class="fa-solid fa-cloud fa fa-2x" style="color: rgba(42, 150, 255,0.4);"></i>
          <h3 style="color: rgba(36, 113, 190, 0.4);">Glissez ici les fichiers</h3>
        </div>
        <div *ngIf="files?.length>0">
          <div class="row" style="margin-bottom: 50px">
            <div class="fileElement col-5" *ngFor="let file of files; let index=index">
                <div class="delete-btn">
                  <i class="fa-solid fa-trash fa-lg" (click)="deleteFile(index)"></i>
                </div>
                <div style="width: 80%;display: inline-block;">
                  <p class="file-name expandable">{{expandFileName(file.name)}}</p>
                  <div class="progress-cont">
                    <div class="progress" [style.width]="file.progress + '%'">
                    </div>
                  </div>
                </div>
                <div style="display: inline-block;font-size: 25px">
                  <i class="fa-solid fa-clipboard "></i>
                </div>
            </div>
          </div>
        </div>
        <input type="file" style="display: none" #fileDropRef2 id="audioDropRef2" multiple
               (change)="fileBrowseHandler($event.target?.files, fileType)"/>
        <label for="audioDropRef2" class="select-label">
          <i class="fa-solid fa-upload fa fa-2x"></i>
          <br>
          Sélectionner un fichier...
        </label>
      </div>
    </div>
  </div>