import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { Validators } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { startWith, map } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { GroupUser } from '../../../../../entity/User/GroupUser';
import { ActionUser } from '../../../../../entity/Opportunity/FilterOpportunite';
import { Observable, Subscription } from 'rxjs';
 
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-filtres-actions-utilisateurs',
  templateUrl: './filtres-actions-utilisateurs.component.html',
  styleUrls: ['./filtres-actions-utilisateurs.component.css']
})
export class FiltresActionsUtilisateursComponent implements OnInit, OnChanges, OnDestroy {
  groupe: GroupUser[];
  filterActionUtilisateur: ActionUser = new ActionUser();
  groupeSelected: any[] = [];
  user: any[];
  @Output() onPushFilterActionsUtilisateurs: EventEmitter<ActionUser> = new EventEmitter();
  userSelected: any[] = [];
  checked: string = '';
   
  @Output() returnToparent = new EventEmitter<boolean>();
  @Input() Reset: any;

  // Mat Chips group
  groupFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredGroup: Observable<GroupUser[]>;
  ListGroup: string[] = [];
  // allGroup: GroupUser[] = [];

  @ViewChild('groupInput') groupInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoGroup') matAutocompleteGroup: MatAutocomplete;


  date_creation_action: string = "";
  date_debut_objectif: string = "";
  date_fin_action: string = "";
  date_fin_objectif: string = "";

  // Mat Chips user
  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userFormCtrl: FormControl = new FormControl('', [Validators.required]);

  filteredUser: Observable<any[]>;
  ListUser: string[] = [];
  allUser: any[] = [];
  _unsubscribeAll: Subscription[]=[];

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoUser') matAutocompleteUser: MatAutocomplete;
  constructor(
    private apiUsersService: ApiUsersService,
    private datePipe: DatePipe) {
  }

  ngOnDestroy(): void {
     this._unsubscribeAll.forEach((subscription: Subscription)=>{
      subscription?.unsubscribe();
    })
  }

  // Group ---------------------------------------------------------------------------------------------

  selectedGroup(event: MatAutocompleteSelectedEvent): void {
    this.ListGroup.push(event.option.viewValue);
    this.groupeSelected.push(event.option.value);
    this.groupInput.nativeElement.value = '';
    this.groupFromCtrl.setValue(null);

  }

  removeGroup(group: string): void {
    const index = this.ListGroup.indexOf(group);
    if (index >= 0) {
      this.ListGroup.splice(index, 1);
      this.groupeSelected.splice(index, 1);
    }
  }

  // private _filterGroup(): GroupUser[] {
  //   let filterValue = this.groupFromCtrl.value.toString().toLowerCase();
  //   if (filterValue == null) filterValue = '';
  //   return this.allGroup.filter(group => group.nom.toLowerCase().indexOf(filterValue) === 0);
  // }

  // User ---------------------------------------------------------------------------------------------

  selectedUser(event: MatAutocompleteSelectedEvent): void {
    this.ListUser.push(event.option.viewValue);
    this.userSelected.push(event.option.value);
    this.userInput.nativeElement.value = '';
    this.userFormCtrl.setValue(null);

  }

  removeUser(util: string): void {
    const index = this.ListUser.indexOf(util);
    if (index >= 0) {
      this.ListUser.splice(index, 1);
      this.userSelected.splice(index, 1);
    }
  }

  private _filter(): any[] {
    let filterValue = this.userFormCtrl.value.toString().toLowerCase();
    if (filterValue == null) filterValue = '';
    return this.allUser.filter(utils => utils.nom.toLowerCase().indexOf(filterValue) === 0);
  }


  ngOnInit(): void {
    // this.getGroupe();
    this.getUser();
    // this.filteredGroup = this.groupFromCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((group: string | null) => group ? this._filterGroup() : this.allGroup.slice()));

    this.filteredUser = this.userFormCtrl.valueChanges.pipe(
      startWith(null),
      map((utils: string | null) => utils ? this._filter() : this.allUser.slice()));
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetProsForm(changes.Reset.currentValue);
  }

  resetProsForm(Reset) {
    if (Reset == true) {
      this.filterActionUtilisateur.date_creation_action = null
      this.filterActionUtilisateur.date_fin_action = null
      this.filterActionUtilisateur.heure_creation_action = ""
      this.filterActionUtilisateur.heure_fin_action = ""
      this.filterActionUtilisateur.date_debut_objectif = null
      this.filterActionUtilisateur.date_fin_objectif = null
      this.filterActionUtilisateur.heure_debut_objectif = ""
      this.filterActionUtilisateur.heure_fin_objectf = ""
      this.ListUser = []
      this.filterActionUtilisateur.list_user = this.userSelected = []
      this.filterActionUtilisateur.operateur = ""
      this.ListGroup = []
      this.filterActionUtilisateur.groupes = this.groupeSelected = []
      this.filterActionUtilisateur.dernier_action = ""
      this.filterActionUtilisateur.analyse = ""
      this.filterActionUtilisateur.enreg_simple = ""
      this.filterActionUtilisateur.enreg_action = ""
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterActionsUtilisateurs.emit(this.filterActionUtilisateur)
    }
  }

  checking(event) {
    if (event.target.checked == true) {
      this.filterActionUtilisateur.dernier_action = 'on'
    }
    else {
      this.filterActionUtilisateur.dernier_action = 'off'
    }
  }

  action(event) {
    if (event.target.checked == true) {
      this.filterActionUtilisateur.enreg_action = 'on'
    }
    else {
      this.filterActionUtilisateur.enreg_action = 'off'
    }
  }

  simple(event) {
    if (event.target.checked == true) {
      this.filterActionUtilisateur.enreg_simple = 'on'
    }
    else {
      this.filterActionUtilisateur.enreg_simple = 'off'
    }
  }

  // getGroupe() {
  //   const unsubscribeGroupe = this.apiUsersService.getGroupUsers()
  //     .subscribe((data: GroupUser[]) => {
  //       this.allGroup = data;
  //     });
  //     this._unsubscribeAll.push(unsubscribeGroupe)
  // }

  getUser() {
    const unsubscribeUser = this.apiUsersService.getAllUsers()
      .subscribe((data: any[]) => {
        this.allUser = data;
      });
      this._unsubscribeAll.push(unsubscribeUser)

  }


  onEmit() {

    this.filterActionUtilisateur.groupes = this.groupeSelected;

    this.filterActionUtilisateur.list_user = this.userSelected;

    this.filterActionUtilisateur.date_creation_action = this.datePipe.transform(this.date_creation_action, 'yyyy-MM-dd');
    this.filterActionUtilisateur.date_debut_objectif = this.datePipe.transform(this.date_debut_objectif, 'yyyy-MM-dd');
    this.filterActionUtilisateur.date_fin_action = this.datePipe.transform(this.date_fin_action, 'yyyy-MM-dd');
    this.filterActionUtilisateur.date_fin_objectif = this.datePipe.transform(this.date_fin_objectif, 'yyyy-MM-dd');

    this.onPushFilterActionsUtilisateurs.emit(this.filterActionUtilisateur)
  }
}
