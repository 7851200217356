import { Component, OnInit } from '@angular/core';
 

@Component({
  selector: 'app-shared-button-actuali',
  templateUrl: './shared-button-actuali.component.html',
  styleUrls: ['./shared-button-actuali.component.css']
})
export class SharedButtonActualiComponent implements OnInit {
   
  constructor() { }

  ngOnInit(): void {
  }

}
