import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-filtre-qualite',
  templateUrl: './filtre-qualite.component.html',
  styleUrls: ['./filtre-qualite.component.css']
})
export class FiltreQualiteComponent implements OnInit, OnChanges {

  filterQualite: any
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushFilterQualite: EventEmitter<any> = new EventEmitter();
  constructor(private chRef: ChangeDetectorRef) { }

  ngOnInit(): void {}
  
  onItemChange() {}

  ngOnChanges(changes: SimpleChanges) {
    this.resetfilterQualiteForm(changes.Reset.currentValue);
  }

  resetfilterQualiteForm(Reset) {
    this.chRef.detectChanges();
    if (Reset == true) {

      this.filterQualite.compteur_qualite = "";
      this.filterQualite.affaire_enquete = "";
      this.filterQualite.qualite4 = "";
      this.filterQualite.qualite1 = "";
      this.filterQualite.qualite2 = "";
      this.filterQualite.qualite3 = "";

      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterQualite.emit(this.filterQualite);
    }
  }

  EmitFilterQualite() {
    this.onPushFilterQualite.emit(this.filterQualite);
  }

}
