import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { Validators, FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { GroupUser } from '../../../../../entity/User/GroupUser';
import { FilterOpportunite, Telephonie } from '../../../../../entity/Opportunity/FilterOpportunite';
import { Observable, Subscription } from 'rxjs';
 
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-filtres-telephonie',
  templateUrl: './filtres-telephonie.component.html',
  styleUrls: ['./filtres-telephonie.component.css'],
})
export class FiltresTelephonieComponent implements OnInit, OnChanges, OnDestroy {
  selectedGroupes = [];
  groupe: GroupUser[];
  filter: FilterOpportunite = new FilterOpportunite();
  filterTelephonie: Telephonie = new Telephonie();
  filterTelephonieOuEt: string = 'OU';
  @Output() onPushFilterTelephonie: EventEmitter<Telephonie> = new EventEmitter();
  userSelected: any[] = [];
  _unsubscribeAll: Subscription[] = [];

  @Output() returnToparent = new EventEmitter<boolean>();
  @Input() Reset: any;
   
  // Mat Chips user
  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userFormCtrl: FormControl = new FormControl('', [Validators.required]);

  date_debut_appel: string = '';
  date_fin_appel: string = '';

  filteredUser: Observable<any[]>;
  ListUser: string[] = [];
  allUser: any[] = [];

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoUser') matAutocompleteUser: MatAutocomplete;

  // Mat Chips group
  groupFromCtrl: FormControl = new FormControl('', [Validators.required]);
  filteredGroup: Observable<GroupUser[]>;
  ListGroup: string[] = [];
  // allGroup: GroupUser[] = [];

  @ViewChild('groupInput') groupInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoGroup') matAutocompleteGroup: MatAutocomplete;
  noValue: boolean;
  noDuree: boolean;

  constructor(private apiUsersService: ApiUsersService, private datePipe: DatePipe) {}

  ngOnDestroy(): void {
    this._unsubscribeAll.forEach((subscription: Subscription) => {
      subscription?.unsubscribe();
    });
  }

  // User ---------------------------------------------------------------------------------------------

  selectedUser(event: MatAutocompleteSelectedEvent): void {
    this.ListUser.push(event.option.viewValue);
    this.userSelected.push(event.option.value);
    this.userInput.nativeElement.value = '';
    this.userFormCtrl.setValue(null);
  }

  removeUser(util: string): void {
    const index = this.ListUser.indexOf(util);
    if (index >= 0) {
      this.ListUser.splice(index, 1);
      this.userSelected.splice(index, 1);
    }
  }

  private _filter(): any[] {
    let filterValue = this.userFormCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allUser.filter((util) =>
      util.nom != null ? util.nom.toLowerCase().indexOf(filterValue.toLowerCase()) === 0 : null
    );
  }

  // Group ---------------------------------------------------------------------------------------------

  selectedGroup(event: MatAutocompleteSelectedEvent): void {
    this.ListGroup.push(event.option.viewValue);
    this.selectedGroupes.push(event.option.value);
    this.groupInput.nativeElement.value = '';
    this.groupFromCtrl.setValue(null);
  }

  removeGroup(group: string): void {
    const index = this.ListGroup.indexOf(group);
    if (index >= 0) {
      this.ListGroup.splice(index, 1);
      this.selectedGroupes.splice(index, 1);
    }
  }

  // private _filterGroup(): GroupUser[] {
  //   let filterValue = this.groupFromCtrl.value.toString().toLowerCase();
  //   if (filterValue === null) filterValue = '';
  //   return this.allGroup.filter((group) => group.nom.toLowerCase().indexOf(filterValue) === 0);
  // }

  ngOnInit(): void {
    // this.getGroupe();
    this.getUser();
    // this.filteredGroup = this.groupFromCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((group: string | null) => (group ? this._filterGroup() : this.allGroup.slice()))
    // );

    this.filteredUser = this.userFormCtrl.valueChanges.pipe(
      startWith(null),
      map((util: string | null) => (util ? this._filter() : this.allUser.slice()))
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetProsForm(changes.Reset.currentValue);
  }

  resetProsForm(Reset) {
    if (Reset === true) {
      this.filterTelephonie.decision_appar = '';
      this.filterTelephonie.operateur_nb_appel = '';
      this.filterTelephonie.operateur_duree = '';
      this.filterTelephonie.nb_appel = '';
      this.filterTelephonie.operateur = '';
      this.filterTelephonie.duree = '';
      this.filterTelephonie.date_debut_appel = '';
      this.filterTelephonie.date_fin_appel = '';
      this.filterTelephonie.heure_debut_appel = '';
      this.filterTelephonie.heure_fin_appel = '';
      this.ListUser = [];
      this.filterTelephonie.list_user = this.userSelected = [];
      this.filterTelephonie.operateur = '';
      this.ListGroup = [];
      this.filterTelephonie.groupes = this.selectedGroupes = [];

      this.date_debut_appel = '';
      this.date_fin_appel = '';

      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterTelephonie.emit(this.filterTelephonie);
    }
  }

  checking(event) {
    if (event.target.checked === true) {
      this.filterTelephonie.decision_appar = '1';
    } else {
      this.filterTelephonie.decision_appar = null;
    }
  }

  // getGroupe() {
  //   const unsubscribeGroup =  this.apiUsersService.getGroupUsers().subscribe((data: GroupUser[]) => {
  //     this.allGroup = data;
  //   });
  //   this._unsubscribeAll.push(unsubscribeGroup)
  // }

  getUser() {
    const unsubscribeUser = this.apiUsersService.getAllUsers().subscribe((data: any[]) => {
      this.allUser = data;
    });
    this._unsubscribeAll.push(unsubscribeUser);
  }

  EmitFilterTelephonie() {
    this.filterTelephonie.list_user = this.userSelected;

    this.filterTelephonie.groupes = this.selectedGroupes;

    this.filterTelephonie.date_debut_appel = this.datePipe.transform(this.date_debut_appel, 'yyyy-MM-dd');
    this.filterTelephonie.date_fin_appel = this.datePipe.transform(this.date_fin_appel, 'yyyy-MM-dd');
    this.onPushFilterTelephonie.emit(this.filterTelephonie);
  }

  getNombre(event: any) {
    if (event) {
      this.noValue = true;
    }
  }

  getDuree(event: any) {
    if (event) {
      this.noDuree = true;
    }
  }
}
